// extracted by mini-css-extract-plugin
export var aArrow1 = "Cc";
export var aArrow2 = "Dc";
export var aEmpty = "sc";
export var aPrice = "vc";
export var aTagAnnounce = "zc";
export var aTagClose = "yc";
export var aTagEntry = "wc";
export var aTagFree = "Bc";
export var aTagLottery = "Ac";
export var aTagPayment = "xc";
export var aText = "Ec";
export var aTitle = "tc";
export var animationMarquee = "Ic";
export var pDetail = "Hc";
export var pItemBatch = "Fc";
export var pItemBatchAbs = "Gc";
export var pItemList = "uc";